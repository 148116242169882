import '../scss/Top/top_style.scss';
import './modules/lazysizes.min'
import './modules/modernizr-custom'
import './modules/slick.min'
import StickySidebar from 'sticky-sidebar'

import $ from 'jquery';

window.jQuery = $;
window.$ = $;

//  グローバルナビ
$(function() {
    var offset = $('#global-navi').offset();

    $(window).scroll(function () {
        if ($(window).scrollTop() > offset.top) {
            $('#global-navi').addClass('fixed');
        } else {
            $('#global-navi').removeClass('fixed');
        }
    });
});

//  タブ
function GethashID (hashIDName){
    if(hashIDName){
        $('.tab li').find('a').each(function() {
            var idName = $(this).attr('href');
            if(idName == hashIDName){
                var parentElm = $(this).parent();
                $('.tab li').removeClass("active");
                $(parentElm).addClass("active");
                $(".area").removeClass("is-active");
                $(hashIDName).addClass("is-active");
            }
        });
    }
}
$('.tab a').on('click', function() {
    var idName = $(this).attr('href');
    GethashID (idName);
    return false;
});
$(window).on('load', function () {
    $('.tab li:first-of-type').addClass("active");
    $('.area:first-of-type').addClass("is-active");
    var hashName = location.hash;
    GethashID (hashName);
});

// スライド
$(function(){
    function sliderSetting(){

        var width = $(window).width();

        if(width <= 780){
            $('.slider').not('.slick-initialized').slick({
                arrows: true,
                dots: true,
                speed: 1000,
                slidesToShow: 1,
                centerMode: true,
                centerPadding: "15%",
                autoplay: true,
                autoplaySpeed: 3000,
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            centerPadding: "10%",
                        },
                    },
                ],
            });
        } else {
            $('.slider.slick-initialized').slick('unslick');
        }
    }

    sliderSetting();

    $(window).resize( function() {
        sliderSetting();
    });
});

var sidebar = new StickySidebar('.sidebar', {
    topSpacing: 75,
    bottomSpacing: 0,
    containerSelector: '#wrapper',
    innerWrapperSelector: '.sidebar-inner'
});
// サイドバー
// $('.sidebar').stickySidebar({
//     topSpacing: 75,
//     bottomSpacing: 0,
//     containerSelector: '#wrapper',
//     innerWrapperSelector: '.sidebar-inner'
// });
// サイドバーレスポンシブ
const CHANGE_WIDTH = 801; // 変更を検知する横幅
const ADD_CLASS = "relative" // 追加するクラス

$(window).on('load resize', function(){
    var i_width = $(window).outerWidth(true);
    if(i_width > CHANGE_WIDTH){
        if($('.sidebar-inner').hasClass(ADD_CLASS)){
            $('.sidebar-inner').eq(0).removeClass(ADD_CLASS);
        }
    } else {
        if(!$('.sidebar-inner').hasClass(ADD_CLASS)){
            $('.sidebar-inner').eq(0).addClass(ADD_CLASS);
        }
    }
});

// メニュー
$(".openbtn").click(function () {
    $(this).toggleClass('active');
    $("#menu").toggleClass('panelactive');
    $('html').toggleClass('fixed');
});

$("#menu a").click(function () {
    $(".openbtn").removeClass('active');
    $("#menu").removeClass('panelactive');
    $('html').removeClass('fixed')
});


// アコーディオン
$('.menu-title').on('click', function() {
    $('.menu-box').slideUp(500);

    var findElm = $(this).next(".menu-box");

    if($(this).hasClass('close')){
        $(this).removeClass('close');
    }else{
        $('.close').removeClass('close');
        $(this).addClass('close');
        $(findElm).slideDown(500);
    }
});

// return to top
$(function(){
    var topBtn=$('#return-top');
    topBtn.hide();

    $(window).scroll(function(){
        if($(this).scrollTop()>250){
            topBtn.fadeIn();
        }else{
            topBtn.fadeOut();
        }
    });

    topBtn.click(function(){
        $('body,html').animate({
            scrollTop: 0},500);
        return false;
    });

});

